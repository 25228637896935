import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * Get session list
 * @function
 * @param {Object} obj - Object contains email and time
 * @returns {Object} - object with data and error
 */
export const listSession = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: [],
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.post(`/api/session`, {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (err) {
    return {
      data: [],
      err: err,
    };
  }
};

/**
 * Get session for image
 * @function
 * @param {object} obj - Object to be sent in the request
 * @returns {object} - Object with data and error
 */
export const getSessionForImage = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.post(`/api/files/images`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      obj,
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * KeyLog data
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const KeyLogData = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: new Error("Token not found"),
    };
  }

  try {
    const response = await axios.post(`/api/keylog`, {obj}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};


/**
 * Get lunch session list
 * @function
 * @param {Object} obj - Object contains email and time
 * @returns {Object} - object with data and error
 */
export const lunchSessionList = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: [],
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.post(`/api/session/lunchTime`, {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (err) {
    return {
      data: [],
      err: err,
    };
  }
};
