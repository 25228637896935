import React from "react";

import { Icon, layout } from "@chakra-ui/react";
import {
  MdAccountCircle,
  MdBarChart,
  MdHome,
  // MdPerson,
  MdSupervisedUserCircle,
  MdTimeline,
  MdTune,
} from "react-icons/md";

// Admin Imports
// import Profile from "views/admin/profile";

import UserDashboard from "components/manageUser/UserDashboard";
import { AttendanceDashboard } from "components/attendanceDashboard/AttendanceDashboard";
import { UserIndex } from "components/UserDashboard/UserIndex";
import SupervisorsDashborad from "components/supervisorDashborad/SupervisorDashborad";
import HomePage from "components/home/HomePage";
import UserActivityPage from "components/userActivity/UserActivityPage";

export const routes = [
  {
    name:"Home Dashboard",
    layout:"/admin",
    path:"/home",
    messageNavbar:"View users list with detils",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: HomePage,
  },
  {
    name: "User Dashboard",
    layout: "/admin",
    path: "/user/dashboard",
    messageNavbar: "View user images, sessions, keylogs and activity",
    icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
    component: UserIndex,
  },
  {
    name:"User Activity",
    layout:"/admin",
    path:"/user/activity-page",
    messageNavbar:"View users activity",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: UserActivityPage,
  },
  {
    name: "Attendance Dashboard",
    layout: "/admin",
    path: "/attendance/dashboard",
    messageNavbar: "User attendance and work",
    icon: <Icon as={MdTune} width="20px" height="20px" color="inherit" />,
    component: AttendanceDashboard,
  },
  {
    name: "Manage Users",
    layout: "/admin",
    path: "/manageuser/dashboard",
    messageNavbar: "Add and remove users",
    icon: (
      <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />
    ),
    component: UserDashboard,
  },
  {
    name: "Manage Supervisors",
    layout: "/admin",
    path: "/supervisors/dashboard",
    messageNavbar: "Assign and remove users to supervisors",
    icon: (
      <Icon
        as={MdSupervisedUserCircle}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SupervisorsDashborad,
  },
  

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   messageNavbar: "User Dashboard",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
];
export const routesSupervisor = [
  {
    name:"Home",
    layout:"/supervisor",
    path:"/home",
    messageNavbar:"View users list with detils",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: HomePage,
  },
  {
    name:"User Activity",
    layout:"/supervisor",
    path:"/user/activity-page",
    messageNavbar:"View users activity",
    icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
    component: UserActivityPage,
  },
  {
    name: "User Dashboard",
    layout: "/supervisor",
    path: "/user/dashboard",
    messageNavbar: "View user images, sessions, keylogs and activity",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: UserIndex,
  },
  {
    name: "Attendance Dashboard",
    layout: "/supervisor",
    path: "/attendance/dashboard",
    messageNavbar: "User attendance and work",
    icon: <Icon as={MdTune} width="20px" height="20px" color="inherit" />,
    component: AttendanceDashboard,
  },
];
