import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import {
  Flex,
  SimpleGrid,
  Box,
  useColorMode,
  Stack,
  Select,
} from "@chakra-ui/react";
import { getUser } from "Utils/Common";
import DropdownCustom from "components/UserDashboard/utils/DropdownCustom";
import { getExtensionUser } from "apis/user";
import { getSupervisorUser } from "apis/supervisor";
import { removeUserSession } from "Utils/Common";
import { IndexActivityWatcher } from "components/UserDashboard/ActivityWatcher/IndexActivityWatcher";
import { KeylogData } from "components/UserDashboard/keylogData";
import { getRelativeTimeRange } from "Utils/Common";

function UserActivityPage() {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const authToken = url.searchParams.get("authToken");
  const { colorMode } = useColorMode();
  const [userName, setUserName] = useState("");
  const [users, setUsers] = useState([]);
  const [activityTime, setActivityTime] = useState("");
  const [browserActivityTime, setBrowserActivityTime] = useState("");
  const [time, setTime] = useState(getRelativeTimeRange("today_so_far"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (getUser().isadmin) {
          response = await getExtensionUser();
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj);
        }
        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (authToken) {
      //fetch data when login use auth token
      //for capture user activity only
      fetchData();
      const currentDate = new Date();
      setTime({
        start: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          9,
          0,
          0,
          0
        ),
        end: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          19,
          0,
          0,
          0
        ),
      });
    } else {
      fetchData();
    }
  }, []);

  const handleUserSelectChange = (e) => {
    setUserName(e.target.value);
  };

  const handleTimeRangeChange = (range) => {
    setTime(range);
  };

  return (
    <Card
      backgroundColor={colorMode === "light" ? "#e9e9e926" : "#101c44"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Stack spacing={5}>
        <Flex
          width={"100%"}
          px={{ base: "10px", md: "20px" }}
          py="10px"
          m={"auto"}
          justifyContent={"space-between"}
          gap={{ base: "10px", md: "10px" }}
          flexDirection={{ base: "column", md: "row" }}
          backgroundColor={colorMode === "light" ? "white" : ""}
          borderRadius={"10px"}
        >
          <Select
            variant="filled"
            value={userName}
            onChange={handleUserSelectChange}
            placeholder="Select user"
          >
            {users.map((item, index) => (
              <option
                key={index}
                value={getUser().isadmin ? item.email : item.useremail}
              >
                {getUser().isadmin
                  ? `${item.firstname} ${item.lastname}`
                  : item.name}
              </option>
            ))}
          </Select>
          <DropdownCustom
            onTimeRangeChange={handleTimeRangeChange}
            time={time}
          />
        </Flex>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={3} padding={colorMode==="light"?"0px": "0px 20px"}>
            <IndexActivityWatcher
              userName={userName}
              time={time}
              setActivityTime={setActivityTime}
              setBrowserActivityTime={setBrowserActivityTime}
            />
            <KeylogData userName={userName} time={time} />
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}

export default UserActivityPage;
