import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import SpinnerCircle from "Utils/SpinnerCircle";
import ExpandRowTable from "./ExpandRowTable";
import { convertMsToHM } from "Utils/Common";
import { convertMsToHMForLunch } from "Utils/Common";
import { secondsToHmsInSmallCase } from "Utils/Common";

function SessionTable(props) {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const { spinnerState, errorMessage, searchedItems } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRow, setExpandedRow] = useState(null);

  const itemsPerPage = 9;
  const pageCount = Math.ceil(searchedItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = searchedItems.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const toggleRow = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };

  const  convertToHoursAndMinutes = (milliseconds) => {
    if (milliseconds <= 0) return "0h 0m";
  
    const totalMinutes = Math.floor(milliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <>
      {spinnerState ? (
        <Flex justifyContent="center" py={5}>
          <SpinnerCircle />
        </Flex>
      ) : errorMessage ? (
        <Flex justifyContent="center" py={5}>
          <Text color="red.500">{errorMessage}</Text>
        </Flex>
      ) : currentData.length === 0 ? (
        <Flex justifyContent="center" py={5}>
          <Text>No users found.</Text>
        </Flex>
      ) : (
        <TableContainer>
          <Table variant="simple" color="gray.500" colorScheme="gray">
            <Thead>
              <Tr>
                <Th pe="10px" borderColor={borderColor}>
                  User
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  No. of Sessions
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  Logged Hours
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  Total Images
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  Active Time
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  Lunch Time
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentData.map((row, index) => (
                <>
                  <Tr key={index}>
                    <Td onClick={() => toggleRow(index)} cursor="pointer">
                      {row.useremail}
                    </Td>
                    <Td>
                      {row.sessions.length === 0 ? "N/A" : row.sessions.length}
                    </Td>
                    <Td>
                      {row.totalLoggedTime === "0:0"
                        ? "N/A"
                        : convertMsToHM(
                        row.totalLoggedTime)}
                    </Td>
                    <Td>{row.totalImages === 0 ? "N/A" : row.totalImages}</Td>
                    <Td>
                      {row.activityTotalTime === 0
                        ? "N/A"
                        : secondsToHmsInSmallCase(row.activityTotalTime)}
                    </Td>
                  <Td>
                    {
                      row.totalBreakTime === 0?
                      "N/A":convertMsToHMForLunch(row.totalBreakTime)
                    }
                  </Td>
                  </Tr>
                  {expandedRow === index && (
                    <Tr>
                      <Td colSpan={5} py={4}>
                        <ExpandRowTable sessions={row.sessions} />
                      </Td>
                    </Tr>
                  )}
                </>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="flex-end" p={5}>
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={6}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              previousLabel={"<"}
              nextLabel={">"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </Flex>
        </TableContainer>
      )}
    </>
  );
}

export default SessionTable;
