import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
  Text,
  UnorderedList,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { TimeIcon } from "@chakra-ui/icons";
import { getRelativeTimeRange } from "Utils/Common";

/**
 * Dropdown component for selecting time ranges
 */
const DropdownCustom = ({ onTimeRangeChange, time }) => {
  const { colorMode } = useColorMode();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeRange, setTimeRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [selectedRelativeTime, setSelectedRelativeTime] =
    useState("today_so_far");
  const [absoluteTime, setAbsoluteTime] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [error, setError] = useState("");
  const dropdownRef = useRef(null);

  const relativeTimeOptions = useMemo(
    () => [
      { label: "Last 5 minutes", value: "last_5_minutes" },
      { label: "Last 15 minutes", value: "last_15_minutes" },
      { label: "Last 30 minutes", value: "last_30_minutes" },
      { label: "Last 1 hour", value: "last_1_hour" },
      { label: "Last 3 hours", value: "last_3_hours" },
      { label: "Last 6 hours", value: "last_6_hours" },
      { label: "Last 12 hours", value: "last_12_hours" },
      { label: "Last 24 hours", value: "last_24_hours" },
      { label: "Last 2 days", value: "last_2_days" },
      { label: "Last 7 days", value: "last_7_days" },
      { label: "Last 30 days", value: "last_30_days" },
      { label: "Last 90 days", value: "last_90_days" },
      { label: "Last 6 months", value: "last_6_months" },
      { label: "Last 1 year", value: "last_1_year" },
      { label: "Last 2 years", value: "last_2_years" },
      { label: "Today", value: "today" },
      { label: "Today so far", value: "today_so_far" },
      { label: "This week", value: "this_week" },
      { label: "This week so far", value: "this_week_so_far" },
      { label: "This month", value: "this_month" },
      { label: "This month so far", value: "this_month_so_far" },
      { label: "This year", value: "this_year" },
      { label: "This year so far", value: "this_year_so_far" },
      { label: "Yesterday", value: "yesterday" },
      { label: "Day before yesterday", value: "day_before_yesterday" },
      { label: "This day last week", value: "this_day_last_week" },
      { label: "Previous week", value: "previous_week" },
      { label: "Previous month", value: "previous_month" },
      { label: "Previous year", value: "previous_year" },
    ],
    []
  );

  const handleRelativeTimeChange = (selectedValue) => {
    setSelectedRelativeTime(selectedValue);
    const range = getRelativeTimeRange(selectedValue);
    onTimeRangeChange(range);
    setError("");
    setDropdownOpen(false);
  };

  const handleAbsoluteTimeChange = (range) => {
    setSelectedRelativeTime("");
    const updatedTime = { ...range };
    setAbsoluteTime(updatedTime);

    if (updatedTime.start && updatedTime.end) {
      const startTime = new Date(updatedTime.start);
      const endTime = new Date(updatedTime.end);
      if (startTime > endTime) {
        setError("Start time must be before end time.");
        return;
      }
      setError("");
      onTimeRangeChange({ start: startTime, end: endTime });
      setDropdownOpen(false);
    }
  };

  const handleDateChange = (key, value) => {
    setTimeRange({
      ...time,
      [key]: value,
    });
  };

  const closeDropdownOnClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeDropdownOnClickOutside);
    };
  }, []);

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Box
      position="relative"
      minWidth={{ base: "100%", md: "54%", lg:"50%" }}
      width={"fit-content"}
      display="inline-block"
      ref={dropdownRef}
    >
      <InputGroup
        height={"97%"}
        border={"1px solid"}
        borderColor={colorMode === "light" ? "#2b6cb0" : "white"}
        borderRadius={"5px"}
      >
        <InputLeftElement
          pointerEvents="none"
          width={"1rem"}
          position={"relative"}
          marginRight={{ base: "2px", md: "5px" }}
          marginLeft={{ base: "2px", md: "5px" }}
        >
          <Icon
            w={{ base: "15px", md: "15px" }}
            as={TimeIcon}
            color={brandColor}
          />
        </InputLeftElement>
        <Input
          border={"none"}
          width={"100%"}
          position={"absolute"}
          left={"1.5rem"}
          right={"2rem"}
          paddingLeft={"0px"}
          fontSize={{ base: "smaller", md: "medium" }}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          readOnly
          placeholder="Select Time Range"
          value={
            selectedRelativeTime
              ? relativeTimeOptions.find(
                  (opt) => opt.value === selectedRelativeTime
                )?.label
              : absoluteTime.start && absoluteTime.end
              ? `${new Date(absoluteTime.start).toLocaleString()} TO ${new Date(
                  absoluteTime.end
                ).toLocaleString()}`
              : ""
          }
          className={
            colorMode === "light"
              ? "DatePicker-border"
              : "custom-datepicker-input"
          }
          cursor="pointer"
          aria-label="Time range selector"
        />
        <InputRightElement
          pointerEvents="none"
          width={"1.5rem"}
          position={"absolute"}
        >
          <Icon as={ChevronDownIcon} w="20px" h="20px" color="gray.500" />
        </InputRightElement>
      </InputGroup>

      {dropdownOpen && (
        <Box
          width={{ base: "320px", md: "550px" }}
          height={{ base: "45vh", md: "45vh" }}
          position="absolute"
          top="100%"
          right="0"
          mt={2}
          bg="white"
          boxShadow="md"
          p={4}
          zIndex={5}
          borderRadius="md"
          border="1px solid"
          borderColor={borderColor}
          role="dialog"
          aria-label="Time range dropdown"
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "5px", md: "10px" }}
          backgroundColor={colorMode === "light" ? "" : "#101c44"}
          color={textColor}
        >
          <Box width={{ base: "100%", md: "60%" }}>
            <Text fontWeight="bold" mb={2}>
              Absolute Time Range
            </Text>
            <Flex flexDirection={"column"} gap={"10px"}>
              {["start", "end"].map((key) => (
                <Box
                  key={key}
                  display={{ base: "flex", md: "flex" }}
                  gap="20px"
                  alignItems={"center"}
                >
                  <Text width={"40px"}>{key === "start" ? "From" : "To"}</Text>
                  <ReactDatePicker
                    showTimeSelect
                    showIcon={true}
                    selected={timeRange[key]}
                    onChange={(value) => handleDateChange(key, value)}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    className={
                      colorMode === "light"
                        ? "datepicker-input"
                        : "custom-datepicker-input"
                    }
                  />
                </Box>
              ))}
            </Flex>

            <Button
              mt={"1rem"}
              colorScheme="blue"
              variant="solid"
              type="submit"
              fontSize={"small"}
              onClick={() => {
                handleAbsoluteTimeChange(timeRange);
              }}
            >
              Apply time range
            </Button>
          </Box>
          <Box overflow={"auto"} width={{ base: "100%", md: "40%" }}>
            <Text fontWeight="bold" mb={2}>
              Relative Time Range
            </Text>
            <UnorderedList ml={0} mb={2} listStyleType={"none"}>
              {relativeTimeOptions.map((option) => (
                <ListItem
                  cursor={"pointer"}
                  padding={"5px 0px"}
                  key={option.value}
                  onClick={() => handleRelativeTimeChange(option.value)}
                >
                  {option.label}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>

          {error && (
            <Text color={"red.500"} fontWeight="bold" mt={4} mb={2}>
              {error}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DropdownCustom;