import { Box, Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { AppEventsCharts } from "./AppEventsCharts";
import { BrowserEventsCharts } from "./BrowserEventsCharts";
import {
  appEvents,
  durationChartData,
  options,
  title,
  getAkfData,
  getWebChromeData,
  getWindowUserData,
} from "../utils/ChartDefaultData";
import { groupDataAndColorAssign } from "../utils/commnon";
import TimelineEventCharts from "./TimelineEventCharts";
import { getAppActivity } from "apis/activityWatcher";
import { secondsToHms } from "Utils/Common";
import { getTimeLineActivity } from "apis/activityWatcher";

export const IndexActivityWatcher = ({
  userFullName,
  userName,
  time,
  setActivityTime,
  setBrowserActivityTime,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const captureRef = useRef(null);

  const [chart, setChart] = useState("");
  const [state, setState] = useState(appEvents);
  const [stateTtitle, setStateTitle] = useState(title);
  const [stateTUrls, setStateUrls] = useState(title);
  const [stateCategory, setStateCategory] = useState(title);
  const [stateDuration, setStateDuration] = useState([]);
  const [stateBrowserDuration, setStateBrowserDuration] = useState(title);
  const [stateDomains, setStateDomains] = useState(appEvents);
  const [apptime, setAppTime] = useState();
  const [browserTime, setBrowserTime] = useState();

  const [seriesData, setseriesData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [timelintDataId, setTimelintDataId] = useState({
    afkId: "",
    webBrowserId: "",
    windowUserId: "",
    afkTotalTimeDuration: "",
    notAfkTotalTimeDuration: "",
  });

  const getAppOrBrowserData = async (obj) => {
    if (!time.start || !time.end || !userName) {
      return;
    }

    setLoadingData(true);
    try {
      const { data, err } = await getAppActivity(obj);
      if (!err && isValidResponse(data)) {
        const dataObject = processData(data);
        updateAppTime(dataObject);
        updateBrowserTime(dataObject);
        updateState(dataObject);
        setLoadingData(false);
      } else {
        handleInvalidResponse();
      }
    } catch (error) {
      console.error("/api/activity cannot get", error);
      // removeUserSession();
    }
  };

  const isValidResponse = (response) => {
    const message = response;

    return (
      message.length > 0 &&
      message.some(
        (message) =>
          message.appEvent.length !== 0 &&
          message.urls.length !== 0 &&
          message.title.length !== 0
      )
    );
  };

  const processData = (message) => {
    return message.reduce(
      (dataObject, item) => {
        dataObject.appEvents.push(...item.appEvent);
        dataObject.catEvents.push(...item.catEvent);
        dataObject.duration = item.duration;
        dataObject.title.push(...item.title);
        dataObject.urls.push(...item.urls);
        dataObject.domains.push(...item.domains);
        dataObject.browserDuration = item.browserDuration;

        return dataObject;
      },
      {
        appEvents: [],
        catEvents: [],
        duration: 0,
        title: [],
        urls: [],
        domains: [],
        browserDuration: 0,
      }
    );
  };

  const updateAppTime = (dataObject) => {
    if (dataObject.duration) {
      const percent = (100 * dataObject.duration) / 32400;
      setAppTime(dataObject.duration);
      setActivityTime(secondsToHms(dataObject.duration));
      setStateDuration([percent.toFixed()]);
    }
  };

  const updateBrowserTime = (dataObject) => {
    if (dataObject.browserDuration) {
      setBrowserTime(dataObject.browserDuration);
      setBrowserActivityTime(secondsToHms(dataObject.browserDuration));
      const percent = (100 * dataObject.browserDuration) / 32400;
      setStateBrowserDuration([percent.toFixed()]);
    }
  };

  const updateState = (dataObject) => {
    updateChartDataState(dataObject.appEvents, setState);
    updateChartDataState(dataObject.title, setStateTitle);
    updateChartDataState(dataObject.urls, setStateUrls);
    updateChartDataState(dataObject.catEvents, setStateCategory);
    updateChartDataState(dataObject.domains, setStateDomains);
  };

  const updateChartDataState = (data, setStateFunction) => {
    if (data.length > 0) {
      const op = data.map((item) => ({
        y: Number(item.duration),
        x: `${
          item.data[0].app ||
          item.data[0].title ||
          item.data[0].url ||
          (item.data[0].$category && item.data[0].$category.join("-")) ||
          item.data[0].$domain
        }: ${secondsToHms(Number(item.duration))}`,
      }));

      setStateFunction((prev) => ({
        ...prev,
        series: [{ data: op }],
      }));
    }
  };

  const handleInvalidResponse = () => {
    setState(appEvents);
    setStateTitle(title);
    setStateCategory(title);
    setStateDuration([]);
    setStateBrowserDuration([]);
    setStateDomains(appEvents);
    setBrowserTime();
    setBrowserActivityTime("");
    setStateUrls(title);
    setAppTime(durationChartData);
    setActivityTime("");
    setLoadingData(false);
  };

  const memoizedGetWebChromeData = useMemo(
    () => async (dataObject, colorData, afkdata) =>
      await getWebChromeData(dataObject, colorData, afkdata),
    []
  );
  
  const memoizedGetWindowUserData = useMemo(
    () => async (dataObject, colorData, totalLength) =>
      await getWindowUserData(dataObject, colorData, totalLength),
    []
  );

  const timelineData = async (obj) => {
    if (time.start && time.end && userName) {
      setLoadingData(true);

      try {
        const { data, err } = await getTimeLineActivity(obj);

        if (!err && data.length > 0) {
          let dataObject = {
            awWatcherAfk: [],
            awWatcherWindow: [],
            awWatcherWeb: [],
            awWatcherAfkId: "",
            awWatcherWindowId: "",
            awWatcherWebId: "",
          };

          for (const item of data) {
            dataObject.awWatcherAfk.push(...item.watcherAfk);
            dataObject.awWatcherWindow.push(...item.watcherWindowUser);
            dataObject.awWatcherWeb.push(...item.watcherWebBrowser);
            dataObject.awWatcherAfkId = item.afkId;
            dataObject.awWatcherWindowId = item.windowUserId;
            dataObject.awWatcherWebId = item.webBrowserId;
          }

          const colorData = groupDataAndColorAssign(
            dataObject.awWatcherWeb,
            dataObject.awWatcherWindow
          );

          // Get data for Timelinechart
          const { afkdata, afkTime, notAfkTime } = await getAkfData(dataObject);
          const webData = await memoizedGetWebChromeData(
            dataObject,
            colorData,
            afkdata.length
          );
          const totalLength = afkdata.length + webData.length;
          const windowUserData = await memoizedGetWindowUserData(
            dataObject,
            colorData,
            totalLength
          );

          const series = [...afkdata, ...webData, ...windowUserData];
          setseriesData(series.length > 0 ? series : []);
          setTimelintDataId({
            afkId: dataObject.awWatcherAfkId,
            webBrowserId: dataObject.awWatcherWebId,
            windowUserId: dataObject.awWatcherWindowId,
            afkTotalTimeDuration: secondsToHms(afkTime),
            notAfkTotalTimeDuration: secondsToHms(notAfkTime),
          });
        } else {
          console.error("Err res from /api/activity/timeline", err);
          setseriesData([]);
          setTimelintDataId({
            afkId: "",
            webBrowserId: "",
            windowUserId: "",
            afkTotalTimeDuration: "",
            notAfkTotalTimeDuration: "",
          });
        }
      } catch (error) {
        console.error("Error fetching timeline data", error);
        setseriesData([]);
        setTimelintDataId({
          afkId: "",
          webBrowserId: "",
          windowUserId: "",
          afkTotalTimeDuration: "",
          notAfkTotalTimeDuration: "",
        });
      } finally {
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const obj = { time, email: userName };
        if (chart === 1 || chart === 2) {
          await getAppOrBrowserData(obj);
        } else if (chart === 3) {
          await timelineData(obj);
        }
      } catch (error) {
        console.error("/api/activity cannot get", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, time, userName]);

  return (
    <Card
      direction="column"
      w="100%"
      p="10px"
      // overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex align="center" w="100%" px="20px" py="10px">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
         Activity Dashboard
        </Text>
      </Flex>
      <Select
        variant="filled"
        value={chart}
        onChange={(e) => setChart(Number(e.target.value))}
        placeholder="Select Event"
        mb={"25px"}
      >
        {options &&
          options.map((item, index) => {
            return (
              <option key={index + "l"} value={item.value}>
                {item.label}
              </option>
            );
          })}
      </Select>
      <Box 
      ref={captureRef}
      >
        {chart === 1 && (
          <AppEventsCharts
            state={state}
            durationChartData={durationChartData}
            stateDuration={stateDuration}
            stateCategory={stateCategory}
            stateTtitle={stateTtitle}
            appTime={apptime}
            loadingData={loadingData}
          />
        )}
        {chart === 2 && (
          <BrowserEventsCharts
            durationChartData={durationChartData}
            stateBrowserDuration={stateBrowserDuration}
            stateDomains={stateDomains}
            stateUrls={stateTUrls}
            browserTime={browserTime}
            loadingData={loadingData}
          />
        )}

        {chart === 3 && seriesData && (
          <TimelineEventCharts
            seriesData={seriesData}
            timelintDataId={timelintDataId}
            loadingData={loadingData}
            time={time}
          />
        )}
      </Box>
    </Card>
  );
};
