import moment from "moment";

// Require the package.json file
const packageJson = require("../../package.json");

// access the version property
export const version = packageJson.version;

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  const userStr = localStorage.getItem("user");
  const tokenStr = localStorage.getItem("token");
  if (!userStr && !tokenStr) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  }
};

//convert date to string
export const dateToString = (now) => {
  return moment(now).add(1, "days").utc().format("MM/DD/YYYY");
};

//return diff b/w min
export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  let timeInHM = convertMsToHM(diffInMs);
  return timeInHM;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

//convert ms to hm
export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

//convert seconds to hms
export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

//convert timestamp to date format DD/MM/YYYY
export function convertTimestampToDateFormat(timestamp) {
  const dateObject = new Date(timestamp);

  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const year = dateObject.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

//returns a relative time range based on the provided key.
export function getRelativeTimeRange(key) {
  const now = new Date();
  switch (key) {
    case "last_5_minutes":
      return { start: new Date(now.getTime() - 5 * 60 * 1000), end: now };
    case "last_15_minutes":
      return { start: new Date(now.getTime() - 15 * 60 * 1000), end: now };
    case "last_30_minutes":
      return { start: new Date(now.getTime() - 30 * 60 * 1000), end: now };
    case "last_1_hour":
      return { start: new Date(now.getTime() - 60 * 60 * 1000), end: now };
    case "last_3_hours":
      return { start: new Date(now.getTime() - 3 * 60 * 60 * 1000), end: now };
    case "last_6_hours":
      return { start: new Date(now.getTime() - 6 * 60 * 60 * 1000), end: now };
    case "last_12_hours":
      return { start: new Date(now.getTime() - 12 * 60 * 60 * 1000), end: now };
    case "last_24_hours":
      return { start: new Date(now.getTime() - 24 * 60 * 60 * 1000), end: now };
    case "last_2_days":
      return {
        start: new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000),
        end: now,
      };
    case "last_7_days":
      return {
        start: new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000),
        end: now,
      };
    case "last_30_days":
      return {
        start: new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000),
        end: now,
      };
    case "last_90_days":
      return {
        start: new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000),
        end: now,
      };
    case "last_6_months":
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      return { start: sixMonthsAgo, end: now };
    case "last_1_year":
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      return { start: oneYearAgo, end: now };
    case "last_2_years":
      const twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
      return { start: twoYearsAgo, end: now };
    case "today":
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      return { start: todayStart, end: now };
    case "today_so_far":
      return { start: new Date().setHours(0, 0, 0, 0), end: now };
    case "this_week":
      const weekStart = new Date();
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      weekStart.setHours(0, 0, 0, 0);
      return { start: weekStart, end: now };
    case "this_week_so_far":
      const thisWeekStart = new Date();
      thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay());
      return { start: thisWeekStart, end: now };
    case "this_month":
      const thisMonthStart = new Date();
      thisMonthStart.setDate(1);
      thisMonthStart.setHours(0, 0, 0, 0);
      return { start: thisMonthStart, end: now };
    case "this_month_so_far":
      const monthStart = new Date();
      monthStart.setDate(1);
      return { start: monthStart, end: now };
    case "this_year":
      const thisYearStart = new Date();
      thisYearStart.setMonth(0);
      thisYearStart.setDate(1);
      thisYearStart.setHours(0, 0, 0, 0);
      return { start: thisYearStart, end: now };
    case "this_year_so_far":
      const yearStart = new Date();
      yearStart.setMonth(0);
      yearStart.setDate(1);
      return { start: yearStart, end: now };
    case "yesterday":
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return {
        start: new Date(yesterday.setHours(0, 0, 0, 0)),
        end: new Date(yesterday.setHours(23, 59, 59, 999)),
      };
    case "day_before_yesterday":
      const dayBeforeYesterday = new Date();
      dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
      return {
        start: new Date(dayBeforeYesterday.setHours(0, 0, 0, 0)),
        end: new Date(dayBeforeYesterday.setHours(23, 59, 59, 999)),
      };
    case "this_day_last_week":
      const thisDayLastWeek = new Date();
      thisDayLastWeek.setDate(thisDayLastWeek.getDate() - 7);
      return {
        start: new Date(thisDayLastWeek.setHours(0, 0, 0, 0)),
        end: new Date(thisDayLastWeek.setHours(23, 59, 59, 999)),
      };
    case "previous_week":
      const previousWeekStart = new Date();
      previousWeekStart.setDate(previousWeekStart.getDate() - 7);
      previousWeekStart.setHours(0, 0, 0, 0);
      return { start: previousWeekStart, end: now };
    case "previous_month":
      const previousMonth = new Date();
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      return { start: new Date(previousMonth.setDate(1)), end: now };
    case "previous_year":
      const previousYear = new Date();
      previousYear.setFullYear(previousYear.getFullYear() - 1);
      previousYear.setMonth(0, 1);
      previousYear.setHours(0, 0, 0, 0);
      return { start: previousYear, end: now };
    default:
      return { start: now, end: now };
  }
}

//for lunch time converted function convert ms to hm
export function convertMsToHMForLunch(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;
  hours = hours % 24;

  if (hours <= 0) {
    return `${padTo2Digits(minutes)} Min`;
  } else {
    return `${hours}h, ${padTo2Digits(minutes)}min`;
  }
}

//For session table only convert small case
//convert seconds to hms
export function secondsToHmsInSmallCase(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
}