import axios from "axios";
import { getToken } from "Utils/Common";

/**
 * Get session list
 * @function
 * @param {Object} obj - Object contains email and time
 * @returns {Object} - object with data and error
 */
export const listUsersWithActivityData = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: [],
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.get(`/api/supervisor/users/detial`, {
      headers: { Authorization: `Bearer ${token}` },
      params: obj,
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (err) {
    return {
      data: [],
      err: err,
    };
  }
};
