import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  // Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/dark_theme_art_02.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import sha512 from "js-sha512";
import { setUserSession } from "Utils/Common";
import { signInUser } from "apis/auth";
import { getToken } from "Utils/Common";

function SignIn(props) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  //check user login or not 
  useEffect(() => {
    const token=getToken()
    const user=JSON.parse( localStorage.getItem('user'))

    if(user && token){
      const destination = user.isadmin
      ? "/admin/home"
      : "/supervisor/home";

      props.history.push(destination)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const hashPassword = sha512(password);

    try {
      const { data, err } = await signInUser({
        username: userName,
        password: hashPassword,
      });

      if (!err) {
        setLoading(false);
        setUserSession(data.token, data.user);

        const destination = data.user.isadmin
          ? "/admin/home"
          : "/supervisor/home";

        props.history.push(destination);
      } else {
        setLoading(false);
        setError(err.message);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }

    setTimeout(() => {
      setError(null);
    }, 2000);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSignIn}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@arnowa.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                color={textColor}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="********"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="filled"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  color={textColor}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                isLoading={loading}
              >
                Sign In
              </Button>
            </FormControl>
          </form>
          {error && <Text style={{ color: "red" }}>{error}</Text>}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
