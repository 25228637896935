import React, { useEffect, useState } from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/admin";
import SupervisorLayout from "layouts/supervisor";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Spinner } from "@chakra-ui/react";
import PrivateRoute from "Utils/PrivateRoute";
import PrivateRouteSupervisor from "Utils/PrivateRouteSupervisor";
import SignIn from "views/auth/signIn";
import { setUserSession } from "Utils/Common";
import { removeUserSession } from "Utils/Common";
import { getUserSession } from "apis/auth";
import { getUserFromToken } from "apis/auth";

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const authToken = url.searchParams.get("authToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, err } = await getUserSession();
        if (!err && data && data.token && data.user) {
          setUserSession(data.token, data.user);
          setAuthLoading(false);
        }
        if (err) {
          removeUserSession();
          setAuthLoading(false);
        }
      } catch (error) {
        console.error("retrieving user session:", error);
        removeUserSession();
        setAuthLoading(false);
      }
    };

    if (authToken) {
      handleUserSignUrlToken(authToken);
    } else {
      fetchData();
    }
  }, [authToken]);

  const handleUserSignUrlToken = async (token) => {
    try {
      const { data, err } = await getUserFromToken(token);
      if (!err && data && data.token && data.user) {
        setUserSession(data.token, data.user);
        setAuthLoading(false);
      }
      if (err) {
        setAuthLoading(false);
      }
    } catch (error) {
      console.error("retrieving user session:", error.message);
      removeUserSession();
      setAuthLoading(false);
    }
  };

  if (authLoading) {
    return (
      <Spinner
        thickness="2px"
        style={{
          width: "50px",
          height: "50px",
          position: "absolute",
          top: "45%",
          left: "50%",
        }}
        size="xl"
      />
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <Switch>
              <Route path={"/auth/sign-in"} component={SignIn} />
              {/* adminUser */}
              <PrivateRoute path="/admin" component={AdminLayout} />
              {/* supervisor */}
              <PrivateRouteSupervisor
                path="/supervisor"
                component={SupervisorLayout}
              />
              <Redirect from="/" to="/auth/sign-in" />
            </Switch>
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
}

export default App;
