import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";
import { KeyLogData } from "apis/session";
import Card from "components/card/Card";
import moment from "moment";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";

export const KeylogData = ({ userName, time }) => {
  const { colorMode } = useColorMode();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [loading, setLoading] = useState(false);

  const [keyBoardlog, setkeyBoardlog] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const handleSubmit = async () => {
    if (time.start && time.end && userName) {
      setLoading(true);
      const obj = {
        start: moment(time.start).utc().format(),
        end: moment(time.end).utc().format(),
        user: userName,
      };

      try {
        const { data, err } = await KeyLogData(obj);
        
        if (!err && data.length > 0) {
          setkeyBoardlog(data);
        }

      } catch (error) {
        console.error("Error in getSession:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const itemsPerPage = 7;
  const pageCount = Math.ceil(keyBoardlog.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = keyBoardlog.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Card
      direction="column"
      w="100%"
      p="10px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        align="center"
        w="100%"
        px="20px"
        py="10px"
        justifyContent={"space-between"}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Keylogs
        </Text>
        <Button
          size="sm"
          onClick={handleSubmit}
          colorScheme="blue"
          // variant="outline"
        >
          Get Keylog
        </Button>
      </Flex>

      <TableContainer p="10px">
        {
          keyBoardlog.length > 0 ? (
            <Table variant="striped" 
            >
              <Thead>
                <Tr>
                  <Th pe="10px" borderColor={borderColor}>
                    Date/Time
                  </Th>
                  <Th pe="10px" borderColor={borderColor}>
                    Key log
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Tr>
                    <Td colSpan={2} textAlign={"center"}
                    bg={
                      colorMode === "light"
                      ? "#f0f0f0"
                      : "#151d3f"
                    }
                    >
                      <SpinnerCircle />
                    </Td>
                  </Tr>
                ) : keyBoardlog.length > 0 && (
                  currentData.map((row, index) => {
                    return (
                      <Tr key={index}
                      className={
                        colorMode === "light"
                          ? "custom-table-row"
                          : "custom-dark-table-row"
                      }
                      >
                        <Td fontSize={"sm"} borderColor={borderColor}>
                          {new Date(row.created_at).toString()}
                        </Td>
                        <Td fontSize={"sm"} borderColor={borderColor}>
                          {JSON.parse(row.keyboard_keys).map((key) => {
                            return <div>{key}</div>;
                          })}
                        </Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          ):(
            <Flex justifyContent={"center"} bg={
              colorMode === "light"
                      ? "#f0f0f0"
                      : "#151d3f"
            }
            padding={"1rem"}
            >
              <Text>No Data Keylog</Text>
            </Flex>
          )
        }
      
        {currentData.length > 0 && (
          <Flex
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: "5px",
            }}
          >
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              previousLabel={"<"}
              nextLabel={">"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </Flex>
        )}
      </TableContainer>
    </Card>
  );
};
