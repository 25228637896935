import React, { useEffect, useState } from "react";
import Card from "components/card/Card";
import {
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
  Stack,
  Box,
} from "@chakra-ui/react";
import SearchUser from "components/manageUser/SearchUser";
import { getUser } from "Utils/Common";
import DropdownCustom from "components/UserDashboard/utils/DropdownCustom";
import { listUsersWithActivityData } from "apis/home";
import moment from "moment";
import { getRelativeTimeRange } from "Utils/Common";
import SessionTable from "./SessionTable";

function HomePage() {
  const { colorMode } = useColorMode();
  const textColor = useColorModeValue("secondaryGray.900", "white");

  // State variables
  const [spinnerState, setSpinnerState] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [time, setTime] = useState(getRelativeTimeRange("today_so_far"));
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { start, end } = time;
      if (start && end) {
        const obj = {
          supervisorEmail: getUser().email,
          time: {
            start: moment(start).utc().format(),
            end: moment(end).utc().format(),
          },
        };

        setSpinnerState(true);
        setErrorMessage(""); // Reset any previous error messages

        try {
          const { data, err } = await listUsersWithActivityData(obj);
          if (!err) {
            setUsers(data.data);
          } else {
            setUsers([]);
            setErrorMessage("Failed to load user data.");
          }
        } catch (error) {
          console.error("Unexpected error while fetching user data:", error);
          setErrorMessage(
            "An unexpected error occurred. Please try again later."
          );
        } finally {
          setSpinnerState(false);
        }
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [time]);

  const searchedItems =
    searchValue.length > 0
      ? users.filter((item) =>
          item.useremail.toLowerCase().includes(searchValue.toLowerCase())
        )
      : users;

  const handleTimeRangeChange = (range) => {
    setTime(range);
  };

  return (
    <Card
      backgroundColor={colorMode === "light" ? "#e9e9e926" : "#101c44"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Stack spacing={5}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            spacing={3}
            padding={colorMode === "light" ? "0px" : "0px 20px"}
          >
            <Card direction="column" w="100%" p="10px">
              <Flex
                align="center"
                w="100%"
                px="20px"
                py="10px"
                justifyContent={"space-between"}
                flexDirection={{ base: "column", md: "row" }}
                gap={"10px"}
                alignItems={{ base: "flex-start", md: "center" }}
              >
                <Text
                  color={textColor}
                  fontSize="22px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  Users
                </Text>
                <Flex gap="5px" width={"80%"} justifyContent={"flex-end"}>
                  <SearchUser
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    width="30%"
                  />
                  <DropdownCustom
                    onTimeRangeChange={handleTimeRangeChange}
                    time={time}
                  />
                </Flex>
              </Flex>

              <SessionTable
                spinnerState={spinnerState}
                errorMessage={errorMessage}
                searchedItems={searchedItems}
              />
            </Card>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}

export default HomePage;
