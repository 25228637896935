import {
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export default function SearchUser({ searchValue, setSearchValue, width }) {
  const { colorMode } = useColorMode();

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <InputGroup
      width={width}
      borderColor={colorMode === "light" ? "#2b6cb0" : "white"}
      color={colorMode === "light" ? "#2b6cb0" : "white"}
    >
      <InputLeftElement pointerEvents="none">
        <SearchIcon color={colorMode === "light" ? "#2b6cb0" : "white"} />
      </InputLeftElement>
      <Input
        color={colorMode === "light" ? "#2b6cb0" : "white"}
        className="searchInput"
        type="text"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSearchChange}
        _hover={{
          borderColor: colorMode === "light" ? "#2b6cb0" : "white",
        }}
        _focus={{
          borderColor: colorMode === "light" ? "#2b6cb0" : "white",
        }}
      />
    </InputGroup>
  );
}
