import {
    Box,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Text,
    useColorMode,
  } from "@chakra-ui/react";
  import React from "react";
  
  function ExpandRowTable({ sessions }) {
      const { colorMode } = useColorMode();
    
    const bgColor = useColorModeValue("#f5f7fe", "#101c44");
    const borderColor = useColorModeValue("gray.200", "white");
  
    return (
      <Box bg={bgColor} p={4} borderRadius="md">
        <Text fontWeight="bold" mb={4}>
          Session Details:
        </Text>
        <TableContainer>
          <Table variant="simple" size="sm" colorScheme="gray">
            <Thead>
              <Tr>
                <Th borderColor={borderColor}>Session ID</Th>
                <Th borderColor={borderColor}>Date</Th>
                <Th borderColor={borderColor}>Start</Th>
                <Th borderColor={borderColor}>End</Th>
                <Th borderColor={borderColor}>IP Address</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sessions.map((session) => (
                <Tr key={session.id}
                className={
                    colorMode === "light"
                      ? "custom-table-row"
                      : "custom-dark-table-row"
                  }
                >
                  <Td borderColor={borderColor} backgroundColor={"transparent"}>{session.id}</Td>
                  <Td borderColor={borderColor} backgroundColor={"transparent"}>{session.date}</Td>
                  <Td borderColor={borderColor} backgroundColor={"transparent"}>{session.start_session}</Td>
                  <Td borderColor={borderColor} backgroundColor={"transparent"}>{session.end_session}</Td>
                  <Td borderColor={borderColor} backgroundColor={"transparent"}>{session.ipaddress}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
  
  export default ExpandRowTable;
  