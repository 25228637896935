import { TimeIcon } from "@chakra-ui/icons";
import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "./MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import { MdStart ,MdImage } from "react-icons/md";


export default function UserReports({
  userSessions,
  userImages,
  activityTime,
  browserActivityTime,
}) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdStart} color={brandColor} />
              }
            />
          }
          name="User Sessions"
          value={userSessions.length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdImage} color={brandColor} />
              }
            />
          }
          name="Total Images"
          value={userImages.length}
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={TimeIcon} color={brandColor} />
              }
            />
          }
          name="Total Active Time"
          value={activityTime.length === 0 ? "NA" : activityTime}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={TimeIcon} color={brandColor} />
              }
            />
          }
          name="Browser Active Time"
          value={browserActivityTime.length === 0 ? "NA" : browserActivityTime}
        /> */}
      </SimpleGrid>
    </Box>
  );
}
